import React from 'react'
import { Hero } from '../hero'

const Page: React.FC = () => {
  return (
    <React.Fragment>
      <Hero />
    </React.Fragment>
  )
}

export default Page
