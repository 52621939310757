import React from 'react'
import { Text, Stack, Image, Box, Flex } from '@chakra-ui/react'
import openAIImage from './openai-logo-horizontal-flat-black.png'
import tensorFlowImage from './TF_FullColor_Horizontal.png'

const platforms = [
  {
    title: 'OpenAI',
    logo: openAIImage,
    width: '150px',
  },
  {
    title: 'TensorFlow',
    logo: tensorFlowImage,
    width: '180px',
  },
]

const HeroPoweredBy: React.FC = () => {
  return (
    <Stack
      direction={['column', null, 'row']}
      alignItems={[null, null, 'center']}
    >
      <Text fontSize="lg" color="gray.400">
        Powered By:
      </Text>

      <Flex alignItems="center">
        {platforms?.map((platform, index) => (
          <Box w={platform.width} key={index}>
            <Image src={platform?.logo} alt={platform.title} />
          </Box>
        ))}
      </Flex>
    </Stack>
  )
}

export default HeroPoweredBy
