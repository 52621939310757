import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Page } from '@app/home';
export const _frontmatter = {
  "title": "Home",
  "fullWidthLayout": true,
  "showFooter": false,
  "navbar": {
    "common": {
      "pos": "fixed"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Page mdxType="Page" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      