import React from 'react'
import {
  Box,
  Stack,
  Container,
  Image,
  Heading,
  Text,
  chakra,
  Badge,
} from '@chakra-ui/react'
import heroImg from './home-hero.png'
import { HeroPoweredBy } from '../hero-powerd-by'

const Hero: React.FC = () => {
  return (
    <Box bg="yellow.100">
      <Stack
        py={['64px']}
        as={Container}
        alignItems="center"
        minH="calc(100vh)"
        spacing="2"
        direction={['column-reverse', 'row']}
      >
        <Stack flex={1} spacing="2" p={[4]}>
          <Box>
            <Badge colorScheme="green">COMING SOON</Badge>
          </Box>

          <Heading fontSize="5xl" fontWeight="bold">
            Future Is <chakra.strong color="green.500">Now</chakra.strong>
          </Heading>
          <Text fontSize="xl" color="gray.500">
            Revolutionize AI by embracing ethical big data.
          </Text>

          <HeroPoweredBy />
        </Stack>

        <Box flex={1}>
          <Image src={heroImg} />
        </Box>
      </Stack>
    </Box>
  )
}

export default Hero
